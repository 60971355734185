import React from "react";

import "../assets/styles/containerStyles/footer.scss";
import {ReactComponent as LogoSolidy} from '../assets/images/footer_logo.svg';

export function Footer(props) {


    return <div className={`implementation ${props.leftSideMenuOpen ? "show" : ""}`}>
        <div className={'inner'}>
            <span>Իրագործումը՝ </span>
            <a href="https://4steps.am">
                <LogoSolidy title="4steps"/>
            </a>
        </div>
    </div>
}
