import {request,_urlProduct} from "../api";
import {PRODUCT_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetProducts = ({
                                reset = true,
                                offset = 0,
                                limit = 20,

                            } = {}) => {

    let url = `${_urlProduct}?offset=${offset}&limit=${limit}`;
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PRODUCT_CONSTS.GET_PRODUCTS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            })
    }
};


export const GetProductById = id => {
    const requestData = {
        url:  `${_urlProduct}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                return res?.data
            })
            .catch(() => {})
    }
};

export const DeleteProductById = id => {
    const requestData = {
        url:  `${_urlProduct}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCT_CONSTS.DELETE_PRODUCT_BY_ID,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateProduct = data => {
    const requestData = {
        url: _urlProduct,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PRODUCT_CONSTS.CREATE_PRODUCT,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ ստեղծվեց!"
                })
                history.push('/products')
            })
            .catch(() => {
            })
    }
};

export const UpdateProductById = (id, data) => {
    const requestData = {
        url: `${_urlProduct}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PRODUCT_CONSTS.UPDATE_PRODUCT_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                })
                history.push('/products')
            })
            .catch(() => {
            })
    }
};

export const ToggleProductVisibility = (id, data) => {
    const requestData = {
        url: `${_urlProduct}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCT_CONSTS.TOGGLE_PRODUCT_VISIBILITY,
                    payload: {
                        id,
                        isHidden: data.isHidden
                    }
                });
                notificationSuccess({
                    description: "Ապրանքը հաջողությամբ փոփոխվեց!"
                })
            })
    }
};
