import {AUTH_CONSTS, COMPOSITE_CONSTS} from "../constants";

export const initialState = {
    contactData: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPOSITE_CONSTS.GET_CONTACT:
            return {
                ...state,
                contactData: action.payload,
            };
        case COMPOSITE_CONSTS.UPDATE_CONTACT:
            return {
                ...state,
                contactData: action.payload
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
