import { HOME_CONSTS} from "../constants";

export const initialState = {
    home: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case HOME_CONSTS.GET_HOME_DATA:
            return {
                ...state,
                home: action.payload ,
            };

        default:
            return state;
    }
}