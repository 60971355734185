import {NEWS_CONSTS} from "../constants";

export const initialState = {
    newsData: null,
    newsById:null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case NEWS_CONSTS.GET_NEWS_DATA:
            return {
                ...state,
                newsData: action.payload || [],
            };
        case NEWS_CONSTS.GET_NEWS_BY_ID:
            return {
                ...state,
                newsById: action.payload || [],
            };
        case NEWS_CONSTS.CREATE_NEWS_ITEM:
            return {
                ...state,
                newsData: [action.payload].concat(state.newsData)
            };
        case NEWS_CONSTS.DELETE_NEWS_ITEM:
            return {
                ...state,
                newsData: state.newsData.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
