import {request, _urlMedia, } from "../api";
import {MEDIA_CONSTS, UTIL_CONSTS} from "../constants";
import {store} from "../store";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const UploadFile = (file, onUploadProgress=null,) => {
    return dispatch => {
        const requestData = {
            url: _urlMedia,
            token: true,
            method: "POST",
            data: file,
            onUploadProgress,
            customHeaders: {
                // "Accept": "application/json",
                // "Content-Type": "multipart/form-data",
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };
        return request(requestData).then(res => {
        })
    }
};


export const GetMedia = () => {
    return dispatch => {
        const requestData = {
            url: _urlMedia,
            token: true,
            method: "GET",
        };
        return request(requestData)
            .then(res => {
                res && res.data && dispatch({
                    type: MEDIA_CONSTS.GET_MEDIA,
                    payload: {
                        data: res.data,
                    },
                })
            })

    }
};

export const UpdateFile = (id, data, type) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });

        const requestData = {
            url: `${_urlMedia}/${type === 'FOLDER' ? 'folder/' : ''}${id}`,
            token: true,
            method: "PATCH",
            data,
        };
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEDIA_CONSTS.UPDATE_MEDIA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ֆայլը հաջողությամբ փոփոխվեց!"
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    }
};

export const DeleteGalleryImage = (id, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEDIA_CONSTS.DELETE_MEDIA_BY_ID,
                    payload: {id, pathList}
                })
            })
    }
};

export const AddFolder = (data, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder`,
            token: true,
            method: "POST",
            data,
        };
        return request(requestData).then(res => {
            // dispatch({
            //     type: MEDIA_CONSTS.ADD_FOLDER,
            //     payload: {
            //         data: res.data,
            //         pathList
            //     }
            // });
            // dispatch({
            //     type: UTIL_CONSTS.END_LOADING
            // });
            // swal({
            //     title: "Հաջողվեց",
            //     text: "Թղթապանակը հաջողությամբ ստեղծվեց!",
            //     icon: "success",
            //     button: "Լավ"
            // })
        }).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    }
};

export const DeleteFolder = (id, pathList) => {
    return dispatch => {
        const requestData = {
            url: `${_urlMedia}/folder/${id}`,
            token: true,
            method: "DELETE",
        };
        return request(requestData).then(res => {
            dispatch({
                type: MEDIA_CONSTS.DELETE_FOLDER,
                payload: {id, pathList}
            });
        })
    }
};

export const toggleMediaView = (state) => {
    store.dispatch({
        type: MEDIA_CONSTS.TOGGLE_MEDIA_VIEW,
        payload: state
    })
};
