import React from "react";

import {HeaderLogo} from "./headerLogo";
import {RightMenu} from "./RightMenu";

import "../../assets/styles/headerStyles/mainHeader.scss";

export function MainHeader(props) {
    const {LogOut, isAdmin, user} = props;

    return <header className="main-header">
        <HeaderLogo/>
        <RightMenu LogOut={LogOut}
                   isAdmin={isAdmin}
                   user={user}/>
    </header>
}