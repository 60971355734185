import {MEDIA_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    mediaItems: {
        itemsList: [],
    },
    mediaView:false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEDIA_CONSTS.GET_MEDIA:
            return {
                ...state,
                mediaItems: {
                    itemsList: action.payload.data
                }
            };
        case MEDIA_CONSTS.UPLOAD_FILE:
        case MEDIA_CONSTS.ADD_FOLDER:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: addFile(state.mediaItems.itemsList, action.payload.pathList, action.payload.data)
                }
            };

        case MEDIA_CONSTS.DELETE_MEDIA_BY_ID:
        case MEDIA_CONSTS.DELETE_FOLDER:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: deleteFile(state.mediaItems.itemsList, action.payload.pathList, action.payload.id)
                }
            };
        case MEDIA_CONSTS.UPDATE_MEDIA:
            return {
                ...state,
                mediaItems: {
                    ...state.mediaItems,
                    itemsList: state.mediaItems.itemsList.map(img => img.id === action.payload.id ? action.payload : img),
                }
            };
        case MEDIA_CONSTS.TOGGLE_MEDIA_VIEW:
            return {
                ...state,
                mediaView:action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

const addFile = (filesList = [], pathList, items) => {
    const data = Array.isArray(items) ? items : [items];
    if (!pathList || !pathList.length) {
        return [...data, ...filesList];
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    newList[currFolderIndex] = {
        ...currentFolder,
        children: addFile(currentFolder.children, pathRightPart, data)
    };
    return newList;
}

const deleteFile = (filesList = [], pathList, id) => {
    if (!pathList || !pathList.length) {
        return filesList.filter(file => file.id !== id);
    }
    const currPath = pathList[0];
    const currFolderIndex = filesList && filesList.findIndex(folder => {
        return folder.type === "FOLDER" && folder.name === currPath
    });
    if (currFolderIndex === -1) {
        return filesList;
    }
    const pathRightPart = pathList.slice(1);
    const newList = [...filesList];
    const currentFolder = newList[currFolderIndex];
    newList[currFolderIndex] = {
        ...currentFolder,
        children: deleteFile(currentFolder.children, pathRightPart, id)
    };
    return newList;
}
