export const FILE_TYPES={
    "video/3gpp": "3gp",
    "video/x-msvideo": "avi",
    "image/bmp": "bmp",
    "text/css": "css",
    "text/csv": "csv",
    "image/vnd.djvu": "djvu",
    "application/msword": "doc",
    "video/x-flv": "flv",
    "image/gif": "gif",
    "text/html": "html",
    "image/vnd.microsoft.icon": "ico",
    "text/x-java-source": "java",
    "image/jpeg": "jpeg",
    "image/jpg": "jpg",
    "image/webp": "webp",
    "application/javascript": "js",
    "application/json": "json",
    "audio/midi": "midi",
    "video/x-mng": "mng",
    "video/quicktime": "mov",
    "audio/mp3": "mp3",
    "audio/mpeg": "mp3",
    "video/mp4": "mp4",
    "video/mpeg": "mpeg",
    "application/vnd.oasis.opendocument.presentation": "odp",
    "application/vnd.oasis.opendocument.spreadsheet": "ods",
    "application/vnd.oasis.opendocument.text": "odt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/ogg": "ogg",
    "application/pdf": "pdf",
    "image/png": "png",
    "application/vnd.ms-powerpoint": "ppt",
    "image/vnd.adobe.photoshop": "psd",
    "application/x-rar-compressed": "rar",
    "image/svg+xml": "svg",
    "application/x-tar": "tar",
    "application/x-bzip-compressed-tar": "tbz",
    "text/plain": "text",
    "image/tiff": "tif",
    "audio/x-wav": "wav",
    "audio/x-ms-wma": "wma",
    "video/x-ms-wmv": "wmv",
    "application/xhtml+xml": "xhtml",
    "application/vnd.ms-excel": "xls",
    "application/xml": "xml",
    "application/x-zip-compressed": "zip",
    "application/zip": "zip",
}
