import { REQUESTS_CONSTS } from "../constants";

export const initialState = {
    requestsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUESTS_CONSTS.GET_REQUESTS:
            return {
                ...state,
                requestsList: action.payload || [],
            };
        case REQUESTS_CONSTS.DELETE_REQUEST_BY_ID:
            return {
                ...state,
                requestsList: state?.requestsList?.filter(req => req.id !== action.payload)
            };
        default:
            return state;
    }
}
