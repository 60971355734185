import {request, _urlRequests} from "../api";
import {REQUESTS_CONSTS} from "../constants";

export const GetRequests = () => {
    const requestData = {
        url: _urlRequests,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: REQUESTS_CONSTS.GET_REQUESTS,
                    payload: data
                });
            })
    }
};

export const DeleteRequestById = id => {
    const requestData = {
        url: `${_urlRequests}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: REQUESTS_CONSTS.DELETE_REQUEST_BY_ID,
                    payload: id
                });
            })
    }
};
