import {PARTNER_CONSTS} from "../constants";

export const initialState = {
    partnersData: null,
    partnerById:null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case PARTNER_CONSTS.SET_PARTNERS_DATA:
            return {
                ...state,
                partnersData: action.payload || [],
            };
        case PARTNER_CONSTS.GET_PARTNERS_BY_ID:
            return {
                ...state,
                partnerById: action.payload || [],
            };
        case PARTNER_CONSTS.CREATE_PARTNER_ITEM:
            return {
                ...state,
                partnersData: [action.payload].concat(state.partnersData)
            };
        case PARTNER_CONSTS.DELETE_PARTNER_ITEM:
            return {
                ...state,
                partnersData: state.partnersData.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
