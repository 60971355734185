// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

// Import styles
import "../../assets/styles/containerStyles/edit-file.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateFile} from "../../redux/actions";
import {history} from "../../configs/history";
import {changeStateField, initTranslations} from "../../utils/helperFunctions";
import {MEDIA_TYPES} from "../../constants/constType";

class EditFile extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['altAttribute'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                name: '',
                path: '',
            },
            errors: {
                name: false,
            },
            isEditing: true,
            requestLoading: false,
            languageTab: props.mainLanguage,
        };
        this.type = null;
        this.currentData = null;
        this.updatedDataMap = new Map();
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = this.getInputValues.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        const fileData = location && location.state && location.state.data;
        this.type = fileData && fileData.type;
        this.folderList = location && location.state && location.state.folderList;
        if (!this.type) {
            history.push('/media')
        } else {
            const translationsData = {};
            fileData.translations.forEach(item => {
                translationsData[item.language] = {
                    altAttribute: item.altAttribute || '',
                    title: item.title || '',
                };
            });
            this.currentData = fileData ? {...fileData} : {};
            fileData && this.setState({
                fieldsData: {
                    name: fileData.name,
                    path: fileData.path,
                    translations: translationsData
                },
            })
        }
    }


    getInputValues({name, value}, languageId) {
        const item = languageId && this.state.fieldsData.translations[languageId];
        if (name === 'name' && !(/^[-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/.test(value))) {
            return;
        }
        const init_Tr = languageId && this.currentData?.translations?.find(tr => tr?.language === languageId);

        languageId && (
            (!init_Tr && value) ||
            (init_Tr && !init_Tr[name] && value) ||
            (init_Tr && init_Tr[name] && (init_Tr[name] !== value))) ?
            this.updatedDataMap.set(name + languageId, value) :
            this.updatedDataMap.delete(name + languageId);

        !languageId && (this.currentData[name] !== value) ? this.updatedDataMap.set(name, value) :
            this.updatedDataMap.delete(name);
        if (languageId) {
            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    translations: {
                        ...this.state.fieldsData.translations,
                        [languageId]: {
                            ...item,
                            [name]: value
                        }
                    }
                }
            })
        } else {
            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    [name]: value
                }
            })
        }
    }

    async updateFile() {
        const {translations, name} = this.state.fieldsData;
        const TR_data = [];
        if (!name) {
            this.setState({
                errors: {
                    name: true
                }
            });
            return;
        }
        Object.keys(translations).forEach(key => {
            let tr_item = {
                language: key,
            };
            let tr_itemIsEmpty = true;
            if (translations[key].altAttribute) {
                tr_item.altAttribute = translations[key].altAttribute;
                tr_itemIsEmpty = false;
            }
            if (translations[key].description) {
                tr_item.description = translations[key].description;
                tr_itemIsEmpty = false;
            }
            if (translations[key].title) {
                tr_item.title = translations[key].title;
                tr_itemIsEmpty = false;
            }
            !tr_itemIsEmpty && TR_data.push(tr_item);
        });
        if (this.updatedDataMap.size) {
            let reqData = {};
            let parentPath = this.currentData && this.currentData.path;
            let parent = this.currentData && this.currentData.parent;
            parentPath = parentPath && parentPath.slice(0, parentPath.lastIndexOf('/'))
            //const parentFolder = this.folderList && this.folderList.length && this.folderList[this.folderList.length - 1];
            if (parentPath) {
                reqData.parentPath = parentPath;
                reqData.name = name;
                reqData.parent = parent
            }
            TR_data.length && (reqData.translations = TR_data);
            name && this.updatedDataMap.has("name") && (reqData.name = name);

            this.props.UpdateFile(this.props.id, reqData, this.type).then(() => {
                history.push({
                    pathname: '/media',
                    state: {
                        folderList: this.folderList,
                        reGetData: true,
                    }
                })
            }).catch((error) => {
            });
        }
    }

    render() {
        const {requestLoading} = this.props;
        const {fieldsData, errors, languageTab} = this.state;
        const currentData = fieldsData.translations[languageTab] || {};
        return <PageWrapper pageTitle={`Փոփոխել ${this.type === 'FOLDER' ? ' Թղթապանակը' : ' Ֆայլը'}`}>
            <div className="admin-edit-file">
                <InputGroup inputType="input"
                            type="text"
                            label="Name"
                            name="name"
                            maxLength={50}
                            required={true}
                            error={errors.name}
                            value={fieldsData.name}
                            onChange={(fields) => this.getInputValues(fields, null)}/>

                {this.type === MEDIA_TYPES.FILE && <>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                                  errorsList={[errors]}/>

                    <InputGroup inputType="input"
                                type="text"
                                label="Alt"
                                name="altAttribute"
                                maxLength={256}
                                value={currentData.altAttribute}
                                onChange={(fields) => this.getInputValues(fields, languageTab)}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Title"
                                name="title"
                                maxLength={256}
                                value={currentData.title}
                                onChange={(fields) => this.getInputValues(fields, languageTab)}/>
                </>}


                <div className="flex-wrapper-right">
                    <LinkButton cb={this.updateFile}
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                title="Փոփոխել"/>
                </div>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateFile
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFile));
