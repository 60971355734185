import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import slider from "./slider";
import partner from "./partner";
import general from "./general";
import subscribers from "./subscribers";
import about from "./about";
import services from "./services";
import product from "./product";
import news from "./news";
import home from "./home"
import request from "./requests"
import orderRequest from "./order-requests"



export default combineReducers({
    auth,
    utils,
    languages,
    media,
    composite,
    moderators,
    staticTexts,
    slider,
    partner,
    general,
    subscribers,
    about,
    services,
    product,
    news,
    request,
    home,
    orderRequest
});
