import {_urlNews, request} from "../api";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";
import {NEWS_CONSTS} from "../constants/newsConsts";

export const GetNewsData = () => {
    const requestData = {
        url: _urlNews,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: NEWS_CONSTS.GET_NEWS_DATA,
                    payload: data
                });
            })
    }
};

export const GetNewsById = (id) => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: NEWS_CONSTS.GET_NEWS_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdateNewsById = (id, data) => {
    const requestData = {
        url: `${_urlNews}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NEWS_CONSTS.UPDATE_NEWS_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Նորությունները հաջողությամբ փոփոխվեց!"
                })
                history.push('/news')
            })
    }
};

export const DeleteNewsById = id => {
    const requestData = {
        url:  `${_urlNews}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: NEWS_CONSTS.DELETE_NEWS_ITEM,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateNews = data => {
    const requestData = {
        url: _urlNews,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NEWS_CONSTS.CREATE_NEWS_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Նորությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/news')
            })
    }
};

export const UpdateNewsPositions = items => {
    const requestData = {
        url: _urlNews,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: NEWS_CONSTS.UPDATE_NEWS_POSITIONS
                });
                notificationSuccess({
                    description: "Նորությունը հաջողությամբ թարմացվեց!"
                })
            })
    }
};
