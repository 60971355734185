// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/media/media-selector-modal.scss";
import clearIcon from '../../assets/images/ic_clear.svg';
import searchIcon from '../../assets/images/ic_search.svg';
import newFileIcon from '../../assets/images/file-manager/new_file.svg';
// Import Components
import {Modal} from "antd";
import {MediaFileCard} from "./MediaFileCard";
import {MediaFolderCard} from "./MediaFolderCard";
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";
import AddMediaFolder from "./AddFolder";
import MediaUploader from "./AddMedia/MediaUploader";

// Import utils
import {GetMedia, UploadFile} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {getFolderData} from "../../containers/media/utils/helperFunctions";
import {
    cancelAddMedia,
    getMedias,
    onDropFile,
    onUploadClick,
    onUploadProgress,
    triggerFileUpload,
    uploadMedias,
} from "./AddMedia/functions/action-functions";
import {changeStateInputValue} from "../../utils/helperFunctions";
import {MEDIA_MODAL_TYPES} from "../../constants/constType";


class MediaSelectorModal extends Component {
    constructor() {
        super();
        this.state = {
            selectedImages: [],
            searchValue: '',
            folderList: [],

            uploading: false,
            uploadProgress: 0,

            addingFiles: [],
            filesIsChoose: false,
        };
        this.selectImage = this.selectImage.bind(this);
        this.insertWithDoubleClick = this.insertWithDoubleClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSearchValue = changeStateInputValue.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.openFolder = this.openFolder.bind(this);

        this.inputElement = React.createRef();
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.cancelAddMedia = cancelAddMedia.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
    }

    componentDidMount() {
        const {mediaItems} = this.props;
        !mediaItems?.itemsList?.length && this.props.GetMedia();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen, appendMedias} = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendMedias)) {
            this.setState({
                selectedImages: appendMedias
            })
        }
    }


    selectImage(item, isSelected) {
        const {mediaType} = this.props;
        const {selectedImages} = this.state;
        if (mediaType === MEDIA_MODAL_TYPES.MEDIA_MAIN) {
            this.setState({
                selectedImages: isSelected ? [] : [item]
            })
        } else {
            this.setState({
                selectedImages: isSelected ? selectedImages.filter(m => m.id !== item.id) : [item, ...selectedImages]
            })

        }
    }

    insertMedia() {
        const {selectedImages} = this.state;
        this.props.getMedia(selectedImages, this.props?.mediaType);
        this.closeModal();
    }

    insertWithDoubleClick(item) {
        this.props.getMedia([item], this.props?.mediaType);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedImages: []
        });
        this.props.closeModal();
    }

    openFolder(folderData) {
        this.setState({
            folderList: [...this.state.folderList, folderData],
            searchValue: '',
        })
    }

    changeFolder = (id) => {
        const {folderList} = this.state;
        if (id === null) {
            this.setState({
                folderList: [],
                searchValue: '',
            })
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            this.setState({
                folderList: newList,
                searchValue: '',
            })
        }
    };

    render() {
        const {isOpen, mediaItems, acceptTypes} = this.props;
        const {selectedImages, searchValue, folderList, filesIsChoose, addingFiles, uploading, uploadProgress} = this.state;
        let itemsList = mediaItems ? mediaItems.itemsList : [];
        let currentFolder = null;
        let pathList = [];


        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
            pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
            itemsList = getFolderData(itemsList, pathList);
        }
        if (searchValue) {
            itemsList = itemsList.filter((item => item.name.toLowerCase().includes(searchValue.toLowerCase())))
        }
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="media-selector-modal">
                {
                    filesIsChoose ?
                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       uploadProgress={uploadProgress}
                                       getMedias={this.getMedias}
                                       uploadMedias={() => this.uploadMedias(currentFolder)}
                                       cacnel={this.cancelAddMedia}/> :
                        <div className="media-selector-modal-inner">
                            <div className="selector-modal-header">
                                <div className="selector-modal-title">
                                    <h1>
                            <span className={'path-item'} onClick={() => this.changeFolder(null)}>
                                Ֆայլեր
                            </span>
                                        {
                                            folderList && !!folderList.length && folderList.map((folder) => {
                                                return <>
                                                    <span> / </span>
                                                    <span className={'path-item'}
                                                          onClick={() => this.changeFolder(folder.id)}>
                                        {folder.name}
                                    </span>
                                                </>
                                            })
                                        }
                                    </h1>
                                </div>
                                <InputGroup
                                    inputType={"input"}
                                    type={'text'}
                                    name={'searchValue'}
                                    value={searchValue}
                                    placeholder={'Փնտրել...'}
                                    onChange={this.getSearchValue}>
                                    <div className={'search-icon'}>
                                        <img src={searchIcon} alt=""/>
                                    </div>
                                </InputGroup>
                                <button className="selector-modal-closing-btn"
                                        onClick={this.closeModal}>
                                    <img src={clearIcon}
                                         className="close_icon" alt=""/>
                                </button>
                            </div>
                            <div className={'action-btns'}>
                                <div className={'media-upload-in-selector-modal'}>
                                    <LinkButton cb={this.triggerFileUpload}
                                                title={<><img
                                                    src={newFileIcon}
                                                    alt="icon"/>Ավելացնել Ֆայլ</>}/>
                                    <input
                                        style={{display: 'none'}}
                                        type="file"
                                        ref={this.inputElement}
                                        multiple={true}
                                        onChange={this.onDropFile}
                                        onClick={this.onUploadClick}
                                    />
                                </div>
                                <AddMediaFolder folderList={folderList}
                                                folderDropDownClassName={'in-selector-modal'}/>
                            </div>

                            <div className={'media-items-wrapper'}>
                                {itemsList && !!itemsList.length && itemsList.map((item, index) => {
                                    const isSelected = selectedImages.find(v => v.id === item.id);
                                    const disabled = acceptTypes.every(type => type !== item.extension)
                                    if (item.type === "FOLDER") {
                                        return <MediaFolderCard key={item.id}
                                                                openFolder={this.openFolder}
                                                                item={item}/>
                                    }
                                    return <MediaFileCard key={item.id}
                                                          selectable={true}
                                                          isSelected={isSelected}
                                                          selectImage={this.selectImage}
                                                          withCopy={true}
                                                          disabled={disabled}
                                                          onDoubleClick={this.insertWithDoubleClick}
                                                          item={item}/>
                                })}
                            </div>
                            <div className="choose-button">
                                <LinkButton title={'Ընտրել'}
                                            disabled={!selectedImages.length}
                                            cb={this.insertMedia}/>
                            </div>

                        </div>}
            </div>
        </Modal>
    }
}

MediaSelectorModal.defaultProps = {
    mediaType: MEDIA_MODAL_TYPES.MEDIA_MAIN,
    multiSelect: false,
}
const mapDispatchToProps = {
    GetMedia,
    UploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSelectorModal);
