// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";


// Import Styles
import '../../assets/styles/dataDisplay/details-header.scss';
import dayjs from "dayjs";


class DetailsHeader extends Component {

    constructor () {
        super();
        this.state = {};
    }

    componentDidMount () {
    }

    render () {
        const { data } = this.props;

        return <div className="details-header">
            <div className="left-part">
                {data?.name && <div>
                    <span className="text-bold">Անուն։</span>
                    <span className="text-grey">{data?.name}</span>
                </div>}
                {data?.email && <div>
                    <span className="text-bold">Էլ․ հասցե։</span>
                    <span className="text-grey">{data?.email}</span>
                </div>}
                {data?.phoneNumber && <div>
                    <span className="text-bold">Հեռախոս։</span>
                    <span className="text-grey">{data?.phoneNumber}</span>
                </div>}
            </div>
            <div className="right-side">
                {data?.createdAt && <span
                    className="right-part-item">{"Ամսաթիվ։"} {dayjs(data?.createdAt).format('DD/MM/YYYY HH:mm')}
                    </span>}
            </div>
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsHeader)
