// Import packages
import React, {PureComponent} from "react";

// Import components
import {Progress, Tooltip} from "antd";

// Import styles
import "../../../assets/styles/media/media-uploader.scss";

//import utils
import {ImageCropper} from "./ImageCropper";
import {MaterialIcon} from "../../utils/Icon";
import {ACCEPT_IMAGE_TYPES} from "../../../constants/acceptedTypes";
import {getFileIcon} from "../../../containers/media/utils/getFileIcon";
import {LinkButton} from "../../buttons/buttons";
import {FILE_TYPES} from "../../../constants/fileTypes";


export default class MediaUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: "",
            editingImage: null,
            editingImageIndex: null,
            croppingActive: false
        };
        this.imageSelector = React.createRef();
        this.getCropImage = this.getCropImage.bind(this);
        this.closeCrop = this.closeCrop.bind(this);
        this.cacnel = this.cacnel.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.resetImage = this.resetImage.bind(this);
        this.editImage = this.editImage.bind(this);
    }


    editImage(editingImage, index) {
        this.setState({
            croppingActive: true,
            imageUrl: editingImage.imageUrl,
            editingImage: editingImage,
            editingImageIndex: index
        });
    }

    getCropImage(image) {
        const {editingImageIndex} = this.state;
        const editingItem = this.props.addingFiles[editingImageIndex];
        const medias = [...this.props.addingFiles]
        medias[editingImageIndex] = {
            ...editingItem,
            ...image
        }
        this.props.getMedias(medias)
        this.closeCrop();
    }

    deleteImage(position) {
        let cropImgList = this.props.addingFiles.filter((_, index) => index !== position);
        this.props.getMedias(cropImgList);
    }

    closeCrop() {
        this.setState({
            imageUrl: '',
            editingImage: null,
            editingImageIndex: null,
            croppingActive: false,
        })
    }

    cacnel() {
        this.props.cacnel();
        this.setState({
            imageUrl: '',
            croppingActive: false,
        })
    }

    resetImage() {
        const {editingImageIndex} = this.state;
        const editingItem = this.props.addingFiles[editingImageIndex];
        const medias = [...this.props.addingFiles]
        medias[editingImageIndex] = {
            imageUrl: editingItem.imageUrl,
            file: editingItem.file
        };
        this.props.getMedias(medias)
        this.closeCrop();
    }


    render() {
        let {croppingActive, imageUrl, editingImage, isEditing} = this.state;
        const {aspect, addingFiles, uploading, uploadProgress} = this.props;
        return <div className={'media-uploader-context'}>
            <div className={`progress ${uploading ? 'show':''}`}>
                <Progress percent={uploadProgress} status={'active'} />
            </div>
            {croppingActive && <ImageCropper
                imageUrl={imageUrl}
                isEditing={isEditing}
                editingImage={editingImage}
                aspect={aspect}
                resetImage={this.resetImage}
                getCropImage={this.getCropImage}
                closeCrop={this.closeCrop}
            />}
            <div className={`media-items-wrapper ${croppingActive ? 'croppingActive' : ''}`}>
                {
                    !!addingFiles?.length && addingFiles.map((item, index) => {
                        const fileType = item?.file?.type;
                        const extension = FILE_TYPES[fileType];
                        const isImage = ACCEPT_IMAGE_TYPES.some(acceptType => acceptType === extension)
                        const imgUrl = isImage ? (item?.croppedPath || item?.imageUrl) :
                            getFileIcon(extension);
                        return <div className="media-item" key={index}>
                            <img src={imgUrl} className={'media-item-icon'} alt=""/>
                            <div className={'item-bottom-part'}>
                                <div className={'name'}>{item?.file?.name}</div>
                            </div>
                            <button
                                className={'btn delete-btn'}
                                onClick={() => {
                                    this.deleteImage(index)
                                }}>
                                <MaterialIcon icon="delete"/>
                            </button>
                            {isImage && <Tooltip color="#108ee9" title={'Փոփոխել'} placement={'left'}>
                                <button
                                    className={'btn edit-btn'}
                                    onClick={() => {
                                        this.editImage(item, index)
                                    }}>
                                    <MaterialIcon icon="edit"/>
                                </button>
                            </Tooltip>}
                        </div>
                    })
                }
            </div>
            <div className={'upload-buttons-wrapper'}>
                <LinkButton title={'Չեղարկել'}
                            className={'cancel-btn'}
                            cb={this.cacnel}
                            disabled={uploading}
                />
                <LinkButton title={'Բեռնել'}
                            cb={this.props.uploadMedias}
                            disabled={croppingActive}
                            loadingWithTitle
                            loading={uploading}
                />
            </div>
        </div>
    }
}
