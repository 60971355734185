export const ContactAddingList = [
    {
        label: "հեռախոսահամար 1",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "հեռախոսահամար",
        name: "phone1",
        maxLength: 20
    },
    {
        label: "հեռախոսահամար 2",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "հեռախոսահամար",
        name: "phone2",
        maxLength: 20
    },
    {
        label: "հեռախոսահամար 3",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "հեռախոսահամար",
        name: "phone3",
        maxLength: 20
    },
];
