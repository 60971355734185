// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../assets/styles/containerStyles/product.scss"
// Import components
import InfiniteScroll from "react-infinite-scroller";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ProductCard from "../../components/cards/ProductCard";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    GetOrderRequestById,
    DeleteProductById,
} from "../../redux/actions";
import DetailsHeader from "../../components/uiElements/DetailsHeader";


class OrderDetails extends Component {
    constructor (props) {
        super(props);
        this.state = {
            orderRequest: null
        }
    }

    async componentDidMount () {
        const orderRequest = await this.props.GetOrderRequestById(this.props.id);
        this.setState({
            orderRequest,
        })
    }

    render () {
        let { orderRequest } = this.state;
        const products = orderRequest?.products || [];


        return <PageWrapper pageTitle={`Պատվեր\` ${orderRequest?.requestNumber || orderRequest?.id}`}>
                <DetailsHeader
                    data={orderRequest}
                />

            <div className={'products'}>
                {products && !!products.length &&
                products.map((item) => {
                    return <ProductCard key={item?.id}
                                        data={item}/>
                })}
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetOrderRequestById,
    DeleteProductById,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
