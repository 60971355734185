// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreatePartner, GetPartnerById, UpdatePartnersById } from "../../redux/actions";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";

class AddEditPartner extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fieldsData: {
                mediaMain: undefined,
                icon: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditPartner = this.addEditPartner.bind(this);
    }

    async componentDidMount () {
        const { isEditing, } = this.state;
        const { id } = this.props;

        if (isEditing) {
            await this.props.GetPartnerById(id)
            const translationsData = {};
            const { partnerById } = this.props;
            const initStateData = {
                translations: translationsData,
                mediaMain: partnerById?.mediaMain,
                icon: partnerById?.icon,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (type, mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(type, mediaArray[0]?.id)
                : this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing, mediaModalType } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set(mediaModalType, null) :
                this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: null
            }
        })
    }


    toggleMediaModalType (type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditPartner () {
        await this.setState({
            requestLoading: true
        });
        const { mediaMain, icon } = this.state.fieldsData;
        let result = true;
        const errors = {};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!icon) {
            errors.icon = true;
            result = false;
        }

        if (result) {

            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    icon: icon.id,
                };

                this.props.CreatePartner(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const reqData = {
                    mediaMain: mediaMain?.id,
                    icon: icon?.id
                };
                this.props.UpdatePartnersById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render () {
        const { fieldsData, errors, mediaModalType, requestLoading, isEditing } = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Գործընկեր' : 'Փոփոխել Գործընկերը'}>
            <section className="slider-add-edit">
                <div className="top-side">
                    <label>Նկար 1<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>Նկար 2<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'icon')}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditPartner}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreatePartner,
    UpdatePartnersById,
    GetPartnerById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPartner)
