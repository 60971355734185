import {request, _urlHome} from "../api";
import {HOME_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetHomeData = () => {
    const requestData = {
        url: _urlHome,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: HOME_CONSTS.GET_HOME_DATA,
                    payload: data
                });
            })
    }
};


export const UpdateHomeData = (data) => {
    const requestData = {
        url: `${_urlHome}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: HOME_CONSTS.UPDATE_HOME,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            })
    }
};