import {SUBSCRIBER_CONSTS} from "../constants/subscribersConsts";

export const initialState = {
    subscribersList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIBER_CONSTS.GET_SUBSCRIBERS:
            return {
                ...state,
                subscribersList: action.payload || [],
            };
        case SUBSCRIBER_CONSTS.DELETE_SUBSCRIBER_BY_ID:
            return {
                ...state,
                subscribersList: state.subscribersList.filter(subscriber => subscriber.id !== action.payload)
            };
        default:
            return state;
    }
}
