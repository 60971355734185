import {SLIDERS_CONSTS} from "../constants";

export const initialState = {
   sliderList: [],
   sliderById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SLIDERS_CONSTS.GET_SLIDERS:
            return {
                ...state,
                sliderList: action.payload || [],
            };
        case SLIDERS_CONSTS.GET_SLIDER_BY_ID:
            return {
                ...state,
                sliderById: action.payload || [],
            };
        case SLIDERS_CONSTS.CREATE_SLIDER_ITEM:
            return {
                ...state,
                sliderList: [action.payload].concat(state.sliderList)
            };
        case SLIDERS_CONSTS.DELETE_SLIDER_ITEM:
            return {
                ...state,
                sliderList: state.sliderList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
