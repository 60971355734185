// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";
import swal from "sweetalert";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateNews, GetNewsById, UpdateNewsById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { Editor } from "../../components/uiElements/Editor";

class AddEditNews extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'details',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                slug: '',
                date: null,
                mediaMain: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getDate = this.getDate.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditNews = this.addEditNews.bind(this);
    }

    async componentDidMount () {
        const { isEditing, fieldsData, } = this.state;
        const { id } = this.props;

        if (isEditing) {
            await this.props.GetNewsById(id)
            const translationsData = {};
            const { newsById } = this.props;
            const editingData = newsById;
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        details: trItem?.details || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain,
                date: editingData?.date ? dayjs(editingData?.date) : '',
                slug: editingData?.slug,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getDate (value) {
        const { fieldsData, errors, isEditing } = this.state;
        console.log('date updated', value )
        if (isEditing) {
            this.updatedDataMap.set('date', value)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                date: value,
            },
            errors: {
                ...errors,
                date: false
            }
        })
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditNews () {
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, slug, date, isHidden } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description',];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if (!date) {
            errors.date = true;
            result = false;
        }
        if (!slug) {
            errors.slug = true;
            result = false;
        }

        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    slug,
                    date,
                    isHidden,
                    translations: getTranslationData(translations)
                };

                await this.props.CreateNews(reqData).catch(e => {

                    console.log(e?.response?.status)
                    if (e?.response?.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Slug-ը արդեն օգտագործված է։",
                            icon: "error",
                            button: 'Լավ'
                        });
                    }
                }).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {

                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                await this.props.UpdateNewsById(this.props.id, reqData).catch(e => {
                    if (e?.response?.status === 409) {
                        swal({
                            title: "Չհաջողվեց!",
                            text: "Slug-ը արդեն օգտագործված է։",
                            icon: "error",
                            button: 'Լավ'
                        });
                    }
                })
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            mediaModalOpen,
            requestLoading,
            date,
            isEditing,
            errorsTabs
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Նորություն' : 'Փոփոխել Նորությունը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <InputGroup inputType={'wrapper'}
                            label={'Ամսաթիվը'}
                            error={errors.date}
                            required={true}>
                    <DatePicker value={fieldsData.date}
                                format="DD/MM/YY"
                                placeholder={'DD/MM/YYYY'}
                                showToday={false}
                                allowClear={true}
                                className={`input-time ${errors.date ? 'dateError' : ""}`}
                                onChange={this.getDate}/>
                </InputGroup>

                <InputGroup inputType="input"
                            type="text"
                            label="Սլագ"
                            placeholder="Սլագ"
                            maxLength={100}
                            name="slug"
                            required={true}
                            value={fieldsData?.slug}
                            error={errors['slug']}
                            onChange={this.getInputValues}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <div className="editor-wrapper">
                        <label>Նկարագրություն</label>
                        <Editor value={currentData?.details || ''}
                                name={'details'}
                                initValue={initData?.details}
                                error={errors['details' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditNews}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateNews,
    UpdateNewsById,
    GetNewsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditNews)
