// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { GetServicesData, UpdateServicesData } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import { Editor } from "../../components/uiElements/Editor";


class AddEditServiceBlock extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'details',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                icon: undefined,
                slug: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditServiceBlock = this.addEditServiceBlock.bind(this);
    }

    async componentDidMount () {
        const { id, servicesData } = this.props;
        const { isEditing, fieldsData } = this.state;
        if (isEditing && !id) {
            history.push('/services');
            return;
        }

        if (isEditing) {
            const editingData = servicesData?.blocks?.find(b => b.slug === id);
            const translationsData = {};

            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = editingData.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    details: trItem?.details || '',
                };
            });
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain || '',
                icon: editingData?.icon || '',
                slug: editingData?.slug || '',
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (type, mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(type, mediaArray[0]?.id)
                : this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing, mediaModalType } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set(mediaModalType, null) :
                this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: null
            }
        })
    }

    toggleMediaModalType (type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditServiceBlock () {
        await this.setState({
            requestLoading: true
        });
        const { servicesData } = this.props;
        const { translations, mediaMain, icon, slug } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if (!icon) {
            errors.icon = true;
            result = false;
        }

        if (!slug) {
            errors.slug = true;
            result = false;
        }

        if (servicesData.blocks?.find(b => b.slug === slug) && this?.currentData?.slug !== slug) {
            errors.slug = true;
            result = false;
            swal({
                title: "Չհաջողվեց!",
                text: "Slug-ը զբաղված է",
                icon: "error",
                button: "Լավ",
            })
        }

        if (result) {
            if (!this.state.isEditing) {
                let updatedBlock = {
                    translations: getTranslationData(translations),
                    mediaMain: mediaMain?.id || '',
                    icon: icon?.id || '',
                    slug,
                }
                const reqData = {
                    blocks: [updatedBlock, ...servicesData.blocks.map(block => {
                        return {
                            ...block,
                            mediaMain: block?.mediaMain?.id || null,
                            icon: block?.icon?.id || null,
                        }
                    })]
                }
                this.props.UpdateServicesData(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                    history.push(`/services`)
                })
            } else {
                const { id } = this.props;
                const trData = getTranslationData(translations);
                const newBlock = {
                    translations: trData,
                    mediaMain: mediaMain?.id || null,
                    icon: icon?.id || null,
                    slug,
                }
                const reqData = {
                    blocks: servicesData.blocks.map(block => {
                        if (block.slug === id) {
                            return newBlock
                        }
                        return {
                            ...block,
                            mediaMain: block?.mediaMain?.id || null,
                            icon: block?.icon?.id || null,
                        }
                    })
                }
                this.props.UpdateServicesData(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                    history.push(`/services`)
                })
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, mediaModalType, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ծառայություն' : 'Փոփոխել Ծառայություն'}>
            <section className="slider-add-edit">
                <div className="top-side">
                    <label>Պատկեր<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'icon')}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>Նկար<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                <div className="info-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Slug"
                                placeholder="Slug"
                                maxLength={100}
                                name="slug"
                                required={true}
                                value={fieldsData?.slug}
                                error={errors['slug']}
                                onChange={this.getInputValues}/>
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Քարտի նկարագրությունը"
                                placeholder="Քարտի նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>

                    <div className="editor-wrapper">
                        <label>Նկարագրություն</label>
                        <Editor value={currentData?.details || ''}
                                name={'details'}
                                initValue={initData?.details}
                                error={errors['details' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditServiceBlock}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditServiceBlock)
