export const MEDIA_CONSTS = {
    UPLOAD_FILE: 'UPLOAD_FILE',
    GET_MEDIA: "GET_MEDIA",
    UPDATE_MEDIA: "UPDATE_MEDIA",
    DELETE_MEDIA_BY_ID: "DELETE_MEDIA_BY_ID",
    ADD_FOLDER: 'ADD_FOLDER',
    DELETE_FOLDER: 'DELETE_FOLDER',
    TOGGLE_MEDIA_VIEW: "TOGGLE_MEDIA_VIEW",
};

