import { ORDER_REQUESTS_CONSTS } from "../constants";

export const initialState = {
    orderRequestsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDER_REQUESTS_CONSTS.GET_ORDER_REQUESTS:
            return {
                ...state,
                orderRequestsList: action.payload || [],
            };
        case ORDER_REQUESTS_CONSTS.DELETE_ORDER_REQUEST_BY_ID:
            return {
                ...state,
                orderRequestsList: state?.orderRequestsList?.filter(req => req.id !== action.payload)
            };
        default:
            return state;
    }
}
