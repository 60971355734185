// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetRequests,
    DeleteRequestById,
} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {deleteItem} from "../../utils/helperFunctions";
import ItemsList from "../../components/ItemsList";
import dayjs from "dayjs";
// import ReactExport from "react-data-export";


class CallRequests extends Component {
    constructor(props) {
        super(props);

        this.deleteRequestItem = this.deleteRequestItem.bind(this);
    }

    componentDidMount() {
        this.props.GetRequests();
    }

    deleteRequestItem(id){
        this.props.DeleteRequestById(id).then(()=>{
            this.props.GetRequests();
        })
    }

    render() {
        let {requestsList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն","Հեռ․ համար", "Էլ․ հասցե", "Ամսաթիվ"],
            largeItems: [],
            actions: ["delete"],
            status: false,
        };
        let itemListInfo = requestsList?.map((request) => {
            const {id,fullName, phoneNumber, email, createdAt} = request;
            return {
                id,
                values: [
                    fullName ?? '-',
                    phoneNumber ?? '-',
                    email ?? '-',
                    dayjs(createdAt).format('DD/MM/YYYY HH:mm')
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteRequestById, 'Զանգի Հայտը', id)
            }
        });
        return <PageWrapper pageTitle={'Զանգի Հայտեր'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading} />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetRequests,
    DeleteRequestById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallRequests);
