import {request, _urlOrderRequests} from "../api";
import {ORDER_REQUESTS_CONSTS} from "../constants";

export const GetOrderRequests = () => {
    const requestData = {
        url: _urlOrderRequests,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: ORDER_REQUESTS_CONSTS.GET_ORDER_REQUESTS,
                    payload: data
                });
            })
    }
};

export const DeleteOrderRequestById = id => {
    const requestData = {
        url: `${_urlOrderRequests}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ORDER_REQUESTS_CONSTS.DELETE_ORDER_REQUEST_BY_ID,
                    payload: id
                });
            })
    }
};

export const GetOrderRequestById = id => {
    const requestData = {
        url: `${_urlOrderRequests}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                return res?.data
            })
    }
};
