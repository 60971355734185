// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../assets/styles/containerStyles/product.scss"
// Import components
import InfiniteScroll from "react-infinite-scroller";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ProductCard from "../../components/cards/ProductCard";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import {
    GetProducts,
    DeleteProductById,
} from "../../redux/actions";


class Product extends Component {
    constructor (props) {
        super(props);
        this.deleteProject = deleteItem.bind(this, props.DeleteProductById, 'Ապրանքը');
    }

    componentDidMount () {
        this.props.GetProducts();
    }

    loadMoreItems = () => {
        const { products } = this.props;
        const { hasMore } = products;
        hasMore && this.props.GetProducts({
            reset: false,
            offset: products.itemsList.length,
        });
    };

    render () {
        let { products } = this.props;
        return <PageWrapper pageTitle={'Ապրանքեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/products/add"}/>
            <InfiniteScroll
                hasMore={products.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'products'}
                useWindow={true}
                initialLoad={false}>
                {products && !!products.itemsList.length &&
                products.itemsList.map((item) => {
                    return <ProductCard key={item?.id}
                                        data={item}/>
                })}
            </InfiniteScroll>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetProducts,
    DeleteProductById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
