// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetOrderRequests,
    DeleteOrderRequestById,
} from "../../redux/actions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { deleteItem } from "../../utils/helperFunctions";
import ItemsList from "../../components/ItemsList";
import dayjs from "dayjs";


class OrderRequests extends Component {
    constructor (props) {
        super(props);

        this.deleteRequestItem = this.deleteRequestItem.bind(this);
    }

    componentDidMount () {
        this.props.GetOrderRequests();
    }

    deleteRequestItem (id) {
        this.props.DeleteOrderRequestById(id).then(() => {
            this.props.GetOrderRequests();
        })
    }

    render () {
        let { orderRequestsList, requestLoading } = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն", "Հեռ․ համար", "Էլ․ հասցե", "Ամսաթիվ"],
            largeItems: [],
            actions: ["details", "delete"],
            status: false,
        };
        let itemListInfo = orderRequestsList?.map((request) => {
            const { id, name, phoneNumber, email, createdAt } = request;
            return {
                id,
                values: [
                    name ?? '-',
                    phoneNumber ?? '-',
                    email ?? '-',
                    dayjs(createdAt).format('DD/MM/YYYY HH:mm')
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteOrderRequestById, 'Պատվերի Հայտը', id)
            }
        });
        return <PageWrapper pageTitle={'Պատվերի Հայտեր'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       detailsLink={"order-requests"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetOrderRequests,
    DeleteOrderRequestById,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRequests);
