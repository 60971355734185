import {PRODUCT_CONSTS} from "../constants";

export const initialState = {
    products: {
        itemsList: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_CONSTS.GET_PRODUCTS:
            return {
                ...state,
                products: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.products.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                }
            };
        case PRODUCT_CONSTS.CREATE_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    itemsList: [action.payload, ...state.products.itemsList],
                }
            };
        case PRODUCT_CONSTS.UPDATE_PRODUCT_BY_ID:
            return {
                ...state,
                products: {
                    ...state.products,
                    itemsList: state?.products?.itemsList?.map(product => product.id === action.payload.id ?
                        action.payload : product)
                }
            };
        case PRODUCT_CONSTS.TOGGLE_PRODUCT_VISIBILITY:
            return {
                ...state,
                products: {
                    ...state.products,
                    itemsList: state?.products?.itemsList?.map(product => product.id === action.payload.id ?
                        {
                            ...product,
                            isHidden: action.payload.isHidden
                        } : product)
                }
            };
        case PRODUCT_CONSTS.DELETE_PRODUCT_BY_ID:
            return {
                ...state,
                products: {
                    ...state.products,
                    itemsList: state.products.itemsList.filter(product => product.id !== action.payload),
                }
            };
        default:
            return state;
    }
}
