import {MODERATOR_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    moderatorsList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MODERATOR_CONSTS.GET_MODERATORS:
            return {
                ...state,
                moderatorsList:  action.payload
            };
        case MODERATOR_CONSTS.ADD_MODERATOR:
            return {
                ...state,
                moderatorsList: [action.payload, ...state.moderatorsList],
            };
        case MODERATOR_CONSTS.EDIT_MODERATOR:
            return {
                ...state,
                moderatorsList: state.moderatorsList.map(moderator =>
                    moderator.id === action.payload.id
                        ? action.payload : moderator
                ),

            };
        case MODERATOR_CONSTS.DELETE_MODERATOR:
            return {
                ...state,
                moderatorsList: state.moderatorsList.filter(moderator => moderator.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
