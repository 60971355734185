// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetServicesData,
    UpdateServicesData
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { MediaFileCard } from "../../components/media/MediaFileCard";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import PageHeader from "../../components/pageContentViews/pageHeader";
import { NestableItem } from "../../components/cards/nestableItem";
import Nestable from "react-nestable";

class Service extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'subTitle',
            'subDescription',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                blocks: [],
            },
            errors: {},
            errorsTabs: [],
            languageTab: props.mainLanguage,
            requestLoading: false,
            isEditing: true,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.updateService = this.updateService.bind(this);
        this.deleteServices = this.deleteServices.bind(this);

    }

    async componentDidMount () {
        await this.props.GetServicesData();
        const { servicesData } = this.props;
        if (servicesData) {
            const { fieldsData } = this.state;
            const translationsData = {};

            if (servicesData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = servicesData?.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        subTitle: trItem?.subTitle || '',
                        subDescription: trItem?.subDescription || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: servicesData?.mediaMain,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                errors: {},
                errorsTabs: [],
                requestLoading: false,
            });

        }
    }


    getMedia (mediaArray) {
        const { fieldsData, errors } = this.state;
        this.currentData?.mediaMain?.id !== mediaArray[0]?.id
            ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
            : this.updatedDataMap.delete('mediaMain');
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData } = this.state;

        this.currentData?.mediaMain ?
            this.updatedDataMap.set("mediaMain", null) :
            this.updatedDataMap.delete("mediaMain");
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async updateService () {
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description', 'subTitle', 'subDescription'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if (result) {
            const reqData = {
                mediaMain: mediaMain.id,
                translations: getTranslationData(translations)
            };
            this.props.UpdateServicesData(reqData).then(() => {
                this.updatedDataMap.clear();
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
            });
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }

    }

    getNestableItems (list) {
        const { mainLanguage } = this.props;
        const items = [];
        list && !!list.length && list.map((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item?.slug,
                title: title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/services/block/edit/${item?.slug}`,
                },
                data: item,
                deleteCb: deleteItem.bind(this, this.deleteServices, 'Ծառայությունը', item?.slug),
                children: []
            })
        });
        return items;
    }

    deleteServices (slug) {
        const { servicesData } = this.props;
        const reqData = {
            blocks: servicesData.blocks.map(block => {
                return {
                    ...block,
                    mediaMain: block?.mediaMain?.id || null,
                    icon: block?.icon?.id || null,
                }
            }).filter(b => b.slug !== slug)
        };
        this.props.UpdateServicesData(reqData)
    }

    onPositionChange (items) {
        const blocks = items.map(item => item.data);

        const reqData = {
            blocks: blocks.map(block => {
                return {
                    ...block,
                    mediaMain: block?.mediaMain?.id || null,
                    icon: block?.icon?.id || null,
                }
            })
        }
        this.props.UpdateServicesData(reqData)
    }

    render () {
        const {
            fieldsData,
            errors,
            languageTab,
            requestLoading,
            mediaModalOpen,
            errorsTabs
        } = this.state;
        const { servicesData } = this.props;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(servicesData?.blocks);
        return <PageWrapper pageTitle={'Ծառայություններ'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Գլխավոր էջի վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="subTitle"
                                value={currentData.subTitle}
                                initValue={initData.subTitle}
                                error={errors['subTitle' + languageTab]}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Գլխավոր էջի նկարագրությունը"
                                placeholder="նկարագրությունը"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Ենթանկարագրություն"
                                placeholder="Ենթանկարագրություն"
                                name="subDescription"
                                value={currentData.subDescription}
                                initValue={initData.subDescription}
                                error={errors['subDescription' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={!this.updatedDataMap.size}
                                cb={this.updateService}/>
                </div>
                <div>
                    <label className={'list-label'}>Բաժիններ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/services/block/add`}/>

                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onPositionChange}
                        renderItem={NestableItem}
                    />

                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Service)
