import {request, _urlServices} from "../api";
import {SERVICES_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetServicesData = () => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: data
                });
            })
    }
};

export const UpdateServicesData = (data) => {
    const requestData = {
        url: `${_urlServices}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ծառայությունը հաջողությամբ փոփոխվեց!"
                });
            })
    }
};
